import browserUtils from '../utils/browserUtils'
import languageUtils from '../utils/languageUtils'

const config = {

  env: '',
  lang: '',

  settings: {
    availableLanguages: ['en', 'fr'],
    defaultLang: 'fr',
    detection: 'user-agent',  // domain, user-agent
    domainLang: '',
    useCookie: true,
    cookieName: 'lang',
  },

  setLang() {
    this.lang = languageUtils.getLang(this.settings, this.settings.defaultLang)
  },

  getEnvConfig() {
    const domain = browserUtils.getDomain()
    if (typeof domain !== 'string') {
      console.log(`Invalid domain: ${domain}`)
      return false
    }
    switch (domain) {
      case 'jedonne.danslarue.org':
        this.env = 'production'
        this.settings.domainLang = ''
        this.setLang()
        return this.env_config.production

      case 'dice.pubtor.com':
        this.env = 'stage'
        this.settings.domainLang = ''
        this.setLang()
        return this.env_config.stage

      default:
        this.env = 'dev'
        this.settings.domainLang = ''
        this.setLang()
        return this.env_config.development
    }
  },

  env_config: {

    production: {
      frontend: {
        disableConsole: false,
        blockDesktop: true,
        comingSoon: true,
        offline: false,
        bypassHash: '3506301',
        site_url_en: 'https://jedonne.danslarue.org/?lang=en',
        site_url_fr: 'https://jedonne.danslarue.org/??lang=fr'
      }
    },

    stage: {
      frontend: {
        disableConsole: false,
        blockDesktop: true,
        comingSoon: false,
        offline: false,
        bypassHash: '3506301',
        site_url_en: 'https://dice.pubtor.com/?lang=en',
        site_url_fr: 'https://dice.pubtor.com/?lang=fr'
      }
    },

    development: {
      frontend: {
        disableConsole: false,
        blockDesktop: true,
        comingSoon: false,
        offline: false,
        bypassHash: '3506301',
        site_url_en: 'http://localhost:3000/?lang=en',
        site_url_fr: 'http://localhost:3000/?lang=fr'
      }
    }

  },

  content: {
    assets: {
      s3_url: 'https://publicisdigital.s3.ca-central-1.amazonaws.com/sites/dice/assets/gifs/'
    },
    meta: {
      en: {
        language: 'en',
        title: 'Dans la rue',
        description: 'Outplay homelessness. Roll the dice to see how your donation helps homeless youth.'
      },
      fr: {
        language: 'fr',
        title: 'Dans la rue',
        description: 'Déjouer l’itinérance. Lancez les dés pour découvrir comment vos dons peuvent aider les jeunes sans-abri.'
      }
    },
    offline: {
      en: {
        description: 'Website offline for scheduled updates. Please check <span className="nowrap">again soon.</span>'
      },
      fr: {
        description: 'Website offline for scheduled updates. Please check <span className="nowrap">again soon.</span>'
      }
    },
    comingsoon: {
      en: {
        description: 'Coming soon...'
      },
      fr: {
        description: ''
      }
    },
    landscape: {
      en: {
        description: 'Please rotate your device to use <span class="nowrap">this website.</span>'
      },
      fr: {
        description: 'Veuillez faire pivoter votre appareil pour consulter ce <span class="nowrap">site web.</span>'
      }
    },
    desktop: {
      en: {
        title: 'HELP YOUTH<br />OUTPLAY HOMELESSNESS',
        description: 'Mobile-only experience.',
        cta: 'Scan',
        src: 'https://publicisdigital.s3.ca-central-1.amazonaws.com/sites/dice/assets/qr/qr-code.png',
        alt: ''
      },
      fr: {
        title: 'AIDEZ LES JEUNES<br />À DÉJOUER L’ITINÉRANCE.',
        description: 'Expérience conçue pour mobile seulement.',
        cta: 'Scannez',
        src: 'https://publicisdigital.s3.ca-central-1.amazonaws.com/sites/dice/assets/qr/qr-code.png',
        alt: ''
      }
    },
    splash: {
      en: {
        title: 'OUTPLAY<br />HOMELESSNESS',
        description: 'Roll the dice to see how your donation helps homeless youth.',
        cta: 'Roll',
        alt1: '',
        alt2: '',
        toggleText: 'FR',
        toggleLink: '/?lang=fr'
      },
      fr: {
        title: 'DÉJOUER<br />L’ITINÉRANCE',
        description: 'Lancez les dés pour découvrir comment vos dons peuvent aider les jeunes <span class="nowrap">sans-abri.</span>',
        cta: 'Lancer',
        alt1: '',
        alt2: '',
        toggleText: 'EN',
        toggleLink: '/?lang=en'
      }
    },
    shake: {
      en: {
        cta: 'Shake',
      },
      fr: {
        cta: 'Braser',
      }
    },
    cta: {
      en: {
        give: 'GIVE',
        roll: 'ROLL',
        rollAgain: 'ROLL AGAIN'
      },
      fr: {
        give: 'JE DONNE',
        roll: 'LANCER',
        rollAgain: 'JE RELANCE'
      }
    },
    results: {
      error: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-white'
        },
        en: {
          title: 'Error',
          description: 'Something went wrong. The total is 0.',
          alt: ''
        },
        fr: {
          title: 'Error',
          description: 'Something went wrong. The total is 0.',
          alt: ''
        }
      },
      roll2: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-white'
        },
        en: {
          title: 'MMMH!',
          description: 'A nice hot meal fills more than your belly. Just $10 is enough for a young person to refuel at the Day Centre, a friendly, judgement-free space.',
          alt: 'EN2'
        },
        fr: {
          title: 'MMMH!',
          description: 'Un bon repas chaud, qui nourrit plus que juste l’estomac! Un petit 10 $ qui permet à un jeune de refaire le plein d\'énergie au Centre de jour, dans un environnement convivial et <span class="nowrap">sans jugement.</span>',
          alt: ''
        }
      },
      roll3: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-white'
        },
        en: {
          title: 'YUM!',
          description: 'A $5 donation buys more than a hot dog. It’s a chance for homeless youth to meet our volunteers at <span class="nowrap">The Van</span> &ndash; a gateway to all our <span class="nowrap">other services.</span>',
          alt: 'EN3'
        },
        fr: {
          title: 'MIAM!',
          description: 'Au-delà d\'un hot dog, un don de 5 $ permet aux jeunes sans-abri de créer un premier contact avec nos bénévoles à bord de la Roulotte.C’est la porte d\'entrée qui mène à nos différents services.',
          alt: ''
        }
      },
      roll4: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-white'
        },
        en: {
          title: 'PHEW!',
          description: 'Good hygiene is empowering, healthy and boosts self-esteem. That’s why your $15 donation also funds basic <span class="nowrap">toiletry kits.</span>',
          alt: 'EN4'
        },
        fr: {
          title: 'FIOU!',
          description: 'Les bonnes habitudes d’hygiène, ça aide à se prendre en main, à améliorer sa santé et à renforcer son sentiment de dignité. C’est pour ça que votre don de <span class="nowrap">15 $</span> sert aussi à fournir des trousses de première nécessité.',
          alt: ''
        }
      },
      roll5: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-yellow'
        },
        en: {
          title: 'AWW!',
          description: 'Joining a support group is a chance to feel heard. Your $25 donation gives homeless youth, including those in the LGBTQ2S+ community, a safe space for lunch and <span class="nowrap">self-expression.</span>',
          alt: 'EN5'
        },
        fr: {
          title: 'AWW!',
          description: 'Intégrer un groupe de soutien, ça donne une chance d’être vraiment écouté. Votre don de <span class="nowrap">25 $</span> permet aux jeunes sans-abri, notamment les membres de la communauté LGBTQ2S+, de se réunir pour un lunch et de s’exprimer dans un lieu <span class="nowrap">sans jugement.</span>',
          alt: ''
        }
      },
      roll6: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-white'
        },
        en: {
          title: 'ZZZ!',
          description: 'Sleep at last! The Bunker is our emergency shelter open 365 days a year. Youth can go there to sleep, eat and chat without fear. Your $30 donation is what makes that cozy <span class="nowrap">room possible.</span>',
          alt: 'EN6'
        },
        fr: {
          title: 'ZZZ!',
          description: 'Enfin, un peu de sommeil! Au Bunker, notre abri d’urgence ouvert 365 jours par an, un jeune peut dormir, manger et parler en toute sécurité. C’est votre don de <span class="nowrap">30 $</span> qui nous permet de lui offrir une chambre accueillante.',
          alt: ''
        }
      },
      roll7: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-white'
        },
        en: {
          title: 'HEY!',
          description: 'It’s nice when youth can talk with a counsellor about what they need. Your donation helps us lend an attentive ear and helping hand. Overcoming homelessness is a <span class="nowrap">team effort.</span>',
          alt: 'EN7'
        },
        fr: {
          title: 'SALUT!',
          description: 'C’est agréable pour un jeune de pouvoir discuter de ses besoins avec un intervenant. Vos dons nous permettent d’offrir une écoute attentive et des mains tendues. Déjouer l’itinérance, ça se fait <span class="nowrap">en équipe.</span>',
          alt: ''
        }
      },
      roll8: {
        styles: {
          top: 'bg-yellow',
          bottom: 'bg-yellow'
        },
        en: {
          title: 'YAY!',
          description: 'There is a link between youth dropout rates and homelessness. Your $40 donation funds a day at The Emmett Johns School and helps young people <span class="nowrap">finish graduate.</span>',
          alt: 'EN8'
        },
        fr: {
          title: 'OHHH!',
          description: 'Chez les jeunes, décrochage scolaire et itinérance sont liés. Votre don de <span class="nowrap">40 $</span> permet d’offrir une journée à l’école Emmett-Johns et d’aider les jeunes à terminer leurs <span class="nowrap">études secondaires.</span>',
          alt: ''
        }
      },
      roll9: {
        styles: {
          top: 'bg-black',
          bottom: 'bg-white'
        },
        en: {
          title: 'GO!',
          description: 'With your donation, Dans la rue can assist homeless youth through every phase of their exit from precarious living conditions. You beat homelessness one step at <span class="nowrap">a time.</span>',
          alt: 'EN9'
        },
        fr: {
          title: 'EN AVANT!',
          description: 'Grâce à vos dons, Dans la rue aide les jeunes en situation d’itinérance à franchir chacune des étapes pour sortir de leur situation précaire. Déjouer l’itinérance, ça se fait un échelon à <span class="nowrap">la fois.</span>',
          alt: ''
        }
      },
      roll10: {
        styles: {
          top: 'bg-black',
          bottom: 'bg-white'
        },
        en: {
          title: 'OUCH!',
          description: 'Discrimination is a fact of life for homeless youth. With your donation, Dans la rue can welcome them without judgement and build a connection. Then our staff can follow up with psychosocial support.',
          alt: 'EN10'
        },
        fr: {
          title: 'OUCH!',
          description: 'Les jeunes sans-abri sont souvent victimes de discrimination. Grâce à vos dons, Dans la rue les accueille sans jugement et crée des liens avec eux. De là, nos intervenants peuvent faire un suivi psychosocial.',
          alt: ''
        }
      },
      roll11: {
        styles: {
          top: 'bg-black',
          bottom: 'bg-white'
        },
        en: {
          title: 'ARGH!',
          description: 'When youth in precarious situations face physical and mental health issues, it can be hard to tap into the resources they need to make a change. Your donation helps Dans la rue give them <span class="nowrap">that support.</span>',
          alt: 'EN11'
        },
        fr: {
          title: 'ARGH!',
          description: 'Quand les jeunes en situation précaire vivent des problèmes de santé physique et mentale, ils n’ont pas toujours accès aux ressources pour améliorer leur situation. Grâce à vos dons, Dans la rue leur offre <span class="nowrap">du soutien.</span>',
          alt: ''
        }
      },
      roll12: {
        styles: {
          top: 'bg-black',
          bottom: 'bg-black'
        },
        en: {
          title: 'OUTPLAY<br />HOMELESSNESS',
          titleClass: 'long',
          description: 'Roll the dice to see how your donation helps <span class="nowrap">homeless youth.</span>',
          alt: 'EN12'
        },
        fr: {
          title: 'OUPS!',
          description: 'Sortir de la rue, ça n’a rien d’un parcours linéaire. Mais vos dons permettent à Dans la rue d’être là pour soutenir les jeunes en situation d’itinérance, de la case départ jusqu’à leur transition <span class="nowrap">vers l’autonomie.</span>',
          alt: ''
        }
      },
    }
  }

}

export default config
