import parse from 'html-react-parser'
import config from '../config/index'
import './BlockDesktop.css'

const BlockDesktop = ({ language }) => {

  const content = config.content.desktop[language]

  const title = content.title
  const description = content.description
  const src = content.src
  const alt = content.alt
  const cta = content.cta

  return (
    <main id="block-desktop" className="block-desktop">
      <h1>
        {parse(title)}
      </h1>
      <p>
        {parse(description)}
      </p>
      <img src={src} alt={alt} />
      <p className="scan">
        {parse(cta)}
      </p>
    </main>
  )

}

export default BlockDesktop
